import { services2 } from '/api';
import { snackbarService } from '/common/snackbarService';
import { parseJSON } from '/helpers';
import { createAppAsyncThunk } from '/store/RootState';

import { IUIState, uiToolkit } from './ui';

export const fetchUserSettings = createAppAsyncThunk('ui/fetchUserSettings', async (_res, api) => {
  try {
    const userSettingsRetrieve = await services2.userProfileService.retrieveSettings();
    userSettingsRetrieve.bifold(
      (res) => {
        if (res?.settings?.settings !== undefined) {
          const parsed = parseJSON<Pick<IUIState, 'isHints' | 'isSplashScreen'>>(res.settings.settings);

          api.dispatch(
            uiToolkit.actions.update({
              ...api.getState().ui,
              isHints: parsed?.isHints !== false,
              isSplashScreen: !!parsed?.isSplashScreen,
            })
          );
        }
      },
      (err) => {
        throw err;
      }
    );
  } catch (e) {
    snackbarService.error(`Retrieve User Settings: ${(e as Error).message}`);
    console.error('user.settings$', e);
  }
});
